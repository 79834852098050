<template>
  <div>
    <div class="container-general">
      <v-card-text
        class="pt-12 px-0"
      >
        <div
          class="container-header"
        >
          <span>
            <h1>Olá, Antes de acessar o painel de documentos, é necessário selecionar uma loja!</h1>
            <p>Clique em uma loja abaixo</p>
          </span>

          <div class="image-content">
            <v-img
              src="@/assets/images/svg/home-panel-companies.svg"
              class="image-header"
              max-height="300"
              max-width="200"
              contain
            />
          </div>
        </div>
      </v-card-text>

      <div
        v-if="isLoadingCompanies"
        class="d-flex justify-center py-10"
      >
        <v-progress-circular
          size="50"
          color="white"
          indeterminate
        />
      </div>

      <div
        v-show="!isLoadingCompanies"
        class="cards-container"
      >
        <div
          v-for="(element, index) in companies"
          :key="index"
          class="card"
        >
          <div class="card-title">
            <h1>{{ element.name }}</h1>
          </div>

          <div class="card-actions">
            <v-img
              max-width="150"
              :src="element.base_64"
            ></v-img>

            <div
              class="btn-action"
              @click="handleNavigate(element)"
            >
              <span style="font-size: 12px; font-weight: 600">
                VIZUALIZAR
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TableOfDocuments
      v-if="showDetailsModal"
      :documents="filteredDocuments"
      :document-type="documentType"
      @close-window="showDetailsModal = false"
    />
  </div>
</template>

<script>
import {
  mdiAccountMultipleOutline,
  mdiBookOpenVariant,
  mdiFileDocument,
  mdiPaperclip,
  mdiShieldAccountVariant,
} from '@mdi/js'

import { sgqDocumentFlux } from '@/assets/images/svg/sgqDocument'
import axiosIns from '@/plugins/axios'
import messages from '@/plugins/showMessageMixin'
import TableOfDocuments from './TableOfDocuments.vue'

export default {
  components: {
    TableOfDocuments,
  },

  mixins: [messages],

  data() {
    return {
      documentType: '',

      companies: [],
      documents: [{ id: '' }],
      documentTypes: [],
      filteredDocuments: [],

      showDetailsModal: false,
      isLoadingCompanies: false,

      MenuData: [{
        title: 'ATR',
        subtitle: 'Atribuição',
        icon: mdiAccountMultipleOutline,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('ATR') },
      },
      {
        title: 'POP',
        subtitle: 'Procedimento Operacional Padrão',
        icon: mdiPaperclip,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('POP') },
      },
      {
        title: 'FORM',
        subtitle: 'Formulário',
        icon: mdiFileDocument,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('FORM') },
      },
      {
        title: 'ANEX',
        subtitle: 'Anexo',
        icon: mdiPaperclip,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('ANEX') },
      },
      {
        title: 'MQ',
        subtitle: 'Manual de Qualidade',
        icon: mdiBookOpenVariant,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('MQ') },
      },
      {
        title: 'PP',
        subtitle: 'Politica de Privacidade',
        icon: mdiShieldAccountVariant,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('PP') },
      },
      {
        title: 'FLX',
        subtitle: 'Fluxograma',
        icon: sgqDocumentFlux,
        handleSubmit: () => { this.filteredDocuments = this.filterDocumentByType('FLX') },
      },
      ],
    }
  },

  async created() {
    await this.getDocuments()
    await this.getCompanies()
  },

  methods: {
    handleNavigate(companie) {
      this.$router.push({ name: 'sgq-panel', params: { id: companie.id } })
    },
    async getCompanies() {
      this.isLoadingCompanies = true

      try {
        const response = await axiosIns.get('/api/v1/sgq/company/index')

        const arrayFormatted = this.formattedData(response.data.data)

        this.companies = arrayFormatted
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingCompanies = false
      }
    },

    capitalizeWords(word) {
      return word.toLowerCase().split(' ').map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')
    },

    formattedData(data) {
      return data.map(item => ({
        ...item,
        name: this.capitalizeWords(item.name),
      }))
    },

    async getDocuments() {
      this.isLoadingDocuments = true

      try {
        const response = await axiosIns.get('api/v1/sgq/registration/index')
        this.documents = response.data?.data
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingDocuments = false
      }
    },

    filterDocumentByType(type) {
      this.documentType = type

      const filteredDocuments = this.documents.filter(document => {
        const documentType = document?.type

        return documentType?.toUpperCase() === type?.toUpperCase()
      })

      const updatedDocuments = filteredDocuments.map(document => ({
        ...document,
        identify: `${document.type}-${document.code}`,
      }))

      this.openModalDetails()

      return updatedDocuments
    },

    openModalDetails() {
      this.showDetailsModal = true
    },
  },
}
</script>

<style scoped>
.container-general{
  background: #312d4b;
  border-radius: 10px;
}

.container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 130px;
  padding: 0px 0px 0px 40px;

  background: #3C3857;
}

.container-header .image-content {
  display: flex;
  height: 200px;
}

.container-header .image-header {
  position: relative;
  right: 0px;
  top: 25px;
}

.container-header span {
  font-size: 12px;
  font-style: normal;
  padding: 10px;
}

.container-header span h1 {
  font-weight: 700;
  color: #e7e3fcde;
}
.container-header span p {
  font-size: 14px;
  font-weight: 500;
}

.cards-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 50px;

  column-gap: 20px;
  row-gap: 20px;
}

.cards-container :nth-child(4) {
  position: relative;
  left: 200px;
}

.cards-container :nth-child(5) {
  position: relative;
  left: 210px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background: #3C3857;

  border-radius: 20px;

  font-size: 10px;
}
/* #312D4B
#3b355a

#312d4b */
.card h1 {
  font-weight: 600;
  font-size: 14px;
}

.card p {
  font-size: 14px;
  font-weight: 500;
}

.card-title{
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.card-actions{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px
}

.btn-action{
  background: #4c466a;
  border-radius: 60px;
  width: 120px;
  height: 40px;
  cursor: pointer;
  color: #e7e3fc;

  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-action:hover{
  background: #e7e3fc48;
  transition: all 0.5s;
}
</style>
